<template>
  <div>
    <qrcode-badge-feedback
      :serial-code="serialCode"
      :totalPoint="totalPoint"
      :count-total-wait="countTotalWait"
      :count-total-wait-max="countTotalWaitMax"
      :countTotal="countTotal"
      :countMax="countTotalMax"
      :feedback-count-total="countFeedbackMadeTotal"
      :feedback-count-max="countFeedbackMadeTotalMax"
      :serialCodeCreation="serialCodeCreation"
      :reload-data="reload"
    />
    <b-card class="mt-3">
      <template #header>
        <card-header
          title="Feedback richiesti"
        />
      </template>

      <feedback-list
        v-if="displayList"
        :open-modal-share="openModalShare"
        :open-modal-note="openModalNote"
        :is-loading="isLoadingFeedback"
      />
    </b-card>
    <modal-notes id="feedbackUserNote" :note="feedbackInfo.note" />
  </div>
</template>

<script>
import { formatDateOnly } from '@/utils/formatterHelper';
import { isNotEmpty } from '@/utils/validators';
import { createBadgeCompleteUrl } from '../../utils/utilities';

const FeedbackList = () => import('@/components/userSections/FeedbackList.vue');
const QrcodeBadgeFeedback = () => import('@/components/userSections/QrcodeBadgeFeedback.vue');
const ModalNotes = () => import('@/components/userSections/ModalNotes.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'UserFeedback',
  components: {
    FeedbackList,
    QrcodeBadgeFeedback,
    ModalNotes,
    CardHeader,
  },

  props: {
    totalPoint: Number,
    countTotalWait: Number,
    countTotalWaitMax: Number,
    countTotal: Number,
    countTotalMax: Number,
    serialCodeCreation: String,
    serialCode: String,
    countFeedbackMadeTotal: Number,
    countFeedbackMadeTotalMax: Number,
    reloadData: Function,
  },
  data() {
    return {
      headingScoring: 'BADGE & FEEDBACK',
      headingStats: 'FEEDBACK',
      currentFilter: 'week',
      loading: false,
      dataset: [],
      // refreshList: 0,
      feedbackInfo: {
        uri: '',
        note: '',
      },
      displayList: true,
    };
  },
  created() {
    if (!this.$store.getters['tableUtils/hasFeedbackType']) {
      this.$store.dispatch('tableUtils/loadFeedbackType', 'it');
    }
  },
  computed: {
    serialCodeCreationDay() {
      return formatDateOnly(this.serialCodeCreation);
    },
    userFeedbackSections() {
      const feedbackTypes = this.$store.getters['tableUtils/getFeedbackType'];
      return feedbackTypes.map(this.prepareFeedback);
    },
    hasScoreData() {
      return this.$store.getters['score/hasCurrentScore'];
    },
    hasFeedbackType() {
      return this.$store.getters['tableUtils/hasFeedbackType'];
    },
    isLoadingFeedback() {
      return this.$store.getters['score/isFeedbackUser']();
    },
    currentName() {
      return this.$store.getters['auth/currentName'];
    },
    shareTitle() {
      return `${this.currentName} ti ha richiesto di inviare un feedback`;
    },
    shareDescription() {
      return `Questo è il link da usare per fornire un feedback su ${this.currentName}`;
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
  },
  mounted() {
    console.log('reload');
    this.fetchData();
  },
  methods: {
    fetchData() {
      console.log('userFeedback fetchData');
      this.searchData(this.currentFilter);
    },
    prepareFeedback(type) {
      const score = this.$store.getters['score/getCurrentScore'] || {};
      const hasScore = this.hasScoreData;
      // console.log('prepareFeedback', type, hasScore, score);
      if (hasScore) {
        // console.log('prepareFeedback hasScore', type.key);
        const feedback = score.sectionTotals[type.key];
        if (isNotEmpty(feedback)) {
          return {
            ...type,
            hasValues: true,
            feedbackValues: { ...feedback },
          };
        }
      }
      return {
        ...type,
        hasValues: false,
      };
    },
    searchData(filter) {
      console.log('userFeedback searchData', filter, this.loading);
      if (!this.loading) {
        this.loading = true;
        this.currentFilter = filter;
        const entityType = this.isCompanyProfile ? 'company' : 'person';
        const entityId = this.isCompanyProfile ? this.$store.getters['subject/currentCompany'].companyId : null;
        this.$store.dispatch('score/fetchFeedbackHistoryStatistics', { entityType, entityId, interval: filter })
          .then((response) => {
            console.log('fetch response', response);
            this.dataset = response.map((item) => ({ x: new Date(item.date), y: item.totalFeedback }));
            this.loading = false;
          }, (error) => {
            console.log('Error fetch', error);
            this.loading = false;
          });
      }
    },
    reload() {
      console.log('reload');
      if (this.reloadData) this.reloadData();
      this.fetchData();
      this.displayList = false;
      // eslint-disable-next-line no-return-assign
      setTimeout(() => this.displayList = true, 20);
    },
    openModalShare(feedback) {
      this.feedbackInfo.note = feedback.note;
      this.feedbackInfo.uri = createBadgeCompleteUrl(feedback.info.feedbackName);
      this.$bvModal.show('feedbackUserBadge');
    },
    openModalNote(feedback) {
      this.feedbackInfo.note = feedback.note;
      this.feedbackInfo.uri = createBadgeCompleteUrl(feedback.info.feedbackName);
      this.$bvModal.show('feedbackUserNote');
    },
  },
};
</script>

<style scoped>

</style>
